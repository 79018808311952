import { Fragment, useEffect, useMemo, useRef } from 'react';
import styled from 'styled-components';

import { colors } from '@news/design-tokens/src/colors';
import { sizeUnits } from '@news/design-tokens/src/sizeUnits';
import type { ArticleFieldsFragment } from '@news/gql';
import { FeedType } from '@news/gql';
import { type ArticleMeta as TArticleMeta, isNotNullish } from '@news/lib';

import { SponsoredByBanner } from 'components/SponsoredByBanner';
import { Typography } from 'components/Typography';
import { ArticleAdPlacement } from 'components/ad-placements/ArticleAdPlacement';
import { Ifragasatt } from 'components/ifragasatt';
import { Label } from 'components/labels';
import { useActiveVideoPlayerContext } from 'contexts/VideoPlayerContext';
import { useAddEmbeddedVideosToPNC } from 'hooks/useAddEmbeddedVideosToPNC';
import { TEST_ID } from 'lib/constants';
import { getPageDetailsForType } from 'lib/helpers';
import { toBrTags } from 'lib/toBrTags';

import { ArticleLeadWrapper } from '../ArticleLeadWrapper';
import { ArticleMeta } from '../ArticleMeta';
import { BodyWrapper } from '../BodyWrapper';
import { ArticleContentWrapper, ArticleWrapper, Lead } from './Article.styles';
import { ArticleFeaturedAsset } from './ArticleFeaturedAsset';
import { ArticleFooter } from './ArticleFooter';
import { ArticleGroupHeading } from './ArticleGroupHeading';
import { RelatedItemList } from './RelatedItemList';
import { Tags } from './Tags';

interface Props {
  article: ArticleFieldsFragment;
  feedSlug?: string;
  useSmallMargin?: boolean;
  videoPlayerIdOverride?: string;
}

export const Article = ({ article, feedSlug, useSmallMargin = false, videoPlayerIdOverride }: Props) => {
  useAddEmbeddedVideosToPNC(article);

  const videoPlayerId = useRef('');

  useEffect(() => {
    videoPlayerId.current = videoPlayerIdOverride ?? crypto.randomUUID();
  }, [videoPlayerIdOverride]);

  const { setActiveVideoPlayerId } = useActiveVideoPlayerContext();

  const shouldRenderAds = !article.isSponsoredArticle && !article.isAdsHidden;
  const articleMeta: TArticleMeta = {
    feedOrigins: article.feedOrigins,
    adKeywords: article.feedOrigins.map((feed) => feed.slug).filter(isNotNullish),
  };
  const articleTags = useMemo(() => {
    return article.feedOrigins.filter((feed) => feed.type === FeedType.Tag);
  }, [article.feedOrigins]);

  const sharePath = getPageDetailsForType(article.__typename).itemPath(article.id, article.slug ?? undefined);

  useEffect(() => {
    // Auto start when article is main content
    setActiveVideoPlayerId(videoPlayerId.current);
  }, [setActiveVideoPlayerId]);

  return (
    <Fragment>
      {article.sponsoredByBanner && <SponsoredByBanner />}
      <ArticleWrapper $isSponsored={article.isSponsoredArticle} $useSmallMargin={useSmallMargin} as="article">
        <ArticleGroupHeading article={article} feedSlug={feedSlug} />
        <ArticleFeaturedAsset article={article} videoPlayerId={videoPlayerId.current} />
        <ArticleContentWrapper>
          {article.liveReportId && <DirectReportLabel>Direktrapportering</DirectReportLabel>}
          <Typography variant="title1" as="h1">
            {article.title}
          </Typography>
          {article.lead && (
            <Lead data-testid={TEST_ID.lead} itemProp="description" variant="title3-strong">
              {toBrTags(article.lead)}
            </Lead>
          )}

          <ArticleMeta article={article} />

          {article.articleLeadRichText && (
            <>
              <ArticleLeadWrapper body={article.articleLeadRichText} data={article.data} />
              {shouldRenderAds && <ArticleAdPlacement keywords={articleMeta.adKeywords} />}
            </>
          )}
          <BodyWrapper body={article.body} data={article.data} renderAds={shouldRenderAds} articleMeta={articleMeta} />

          {article.liveReportId && (
            <>
              <DirectReportingDivider />
              <Ifragasatt liveReportId={article.liveReportId} />
            </>
          )}

          {articleTags && articleTags.length > 0 && <Tags tags={articleTags} />}

          <ArticleFooterWithPadding
            sharePath={sharePath}
            byline={article.byline}
            bylineName={article.bylineName}
            bylineEmail={article.bylineEmail}
          />
        </ArticleContentWrapper>
        <RelatedItemList article={article} />
      </ArticleWrapper>
    </Fragment>
  );
};

const DirectReportLabel = styled(Label)`
  margin-bottom: ${sizeUnits[8]};
`;

const ArticleFooterWithPadding = styled(ArticleFooter)`
  margin-top: ${sizeUnits[16]};
`;

const DirectReportingDivider = styled.hr`
  height: 2px;
  background: ${colors.black[20]};
  border: none;
  width: 100%;
`;
