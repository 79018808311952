import styled from 'styled-components';
import { ItemTracker } from 'tracking/ItemTracker';

import { colors } from '@news/design-tokens/src/colors';

import { Typography } from './Typography';

const url = 'https://expekt.se';

export const SponsoredByBanner = () => {
  return (
    <ItemTracker
      item={{
        item_id: 'sponsored-by-expekt',
        item_type: 'SponsoredByBanner',
        item_title: 'sponsored by expekt',
        item_target_url: url,
        item_tags: null,
      }}
    >
      <BannerLink href={url} target="_blank" rel="noopener">
        <TextWrapper>
          <Typography variant="body2" style={{ color: 'gray' }}>
            Presenteras av:
          </Typography>
        </TextWrapper>
        <Logo />
      </BannerLink>
    </ItemTracker>
  );
};

const BannerLink = styled.a`
  display: flex;
  background: ${colors.yellow.soft};
  height: 50px;
  align-items: center;
  justify-content: center;
  position: relative;
  margin-bottom: -20px;
  text-decoration: none;
  color: inherit;

  ${({ theme }) => theme.mq.mobile} {
    margin: -8px 0;
  }
`;

const TextWrapper = styled.div`
  position: absolute;
  left: 16px;
  top: 0;
  bottom: 0;
  display: flex;
  align-items: center;
  ${({ theme }) => theme.mq.mobile} {
    left: 8px;
  }
`;

const Logo = styled.div`
  position: relative;
  width: 150px;
  height: 60px;
  margin-top: 8px;

  &::before {
    content: '';
    position: absolute;
    background-image: url('/assets/images/expekt-logo.svg');
    background-repeat: no-repeat;
    background-size: contain;
    background-position: center;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
  }

  ${({ theme }) => theme.mq.tablet} {
    height: 70px;
    width: 160px;
  }
`;
